import axios from 'axios';
import {ISSUE_STATUSES} from '@/config/teeth.js';

// import { ERUPTION_STATES } from '@/config/teeth.js';

class ApiClient {
    constructor(apiOrigin) {

        this.defaultRequestTimeout = 15000;
        this.slowRequestTimeout = 30000; // for requests that take a long time on the server, like sending emails

        this.legacyAxios = axios.create({
            baseURL: apiOrigin + 'legacy-api/',
            timeout: this.defaultRequestTimeout,
            headers: {'Accept': 'application/json'}
        });

        // :DEBUG: uncomment this to intercept and modify patient data responses from API (part 1, see part 2 below)

        // this.legacyAxios.interceptors.response.use((response) => {
        //     if (this.isAGetPatientResponse(response)) {
        //         response = this.preprocessGetPatientResponse(response);
        //     }
        //     return response;
        // }, (error) => {
        //     return Promise.reject(error);
        // });

        this.coreAxios = axios.create({
            baseURL: apiOrigin + 'core-api/v1',
            timeout: this.defaultRequestTimeout,
            headers: {'Accept': 'application/json'}
        });

        this.patientsAxios = axios.create({
            baseURL: apiOrigin + 'patients-api/v1',
            timeout: this.defaultRequestTimeout,
            headers: {'Accept': 'application/vnd.api+json'}
        });

        this.sessionToken = null;
        this.locale = null;
        this.patientIsModifiable = null;
    }

    // :DEBUG: uncomment this to intercept and modify patient data responses from API (part 2, see part 1 above)

    // isAGetPatientResponse(response) {
    //     const urlParts = response.config.url.split("/");
    //     if (urlParts.length == 4 && urlParts[0] == "sessions" && urlParts[2] == "patients" && urlParts[3].length == 36) {
    //         return true;
    //     }
    //     return false;
    // }

    // preprocessGetPatientResponse(response) {
    //     if (!response.data || !response.data.teeth) {
    //         return response;
    //     }
    //     // mark all teeth in the response as erupted
    //     const dates = Object.keys(response.data.teeth);
    //     dates.forEach((date) => {
    //         const toothNumbers = Object.keys(response.data.teeth[date]);
    //         toothNumbers.forEach((toothNumber) => {
    //             if (!response.data.teeth[date][toothNumber].eruption) {
    //                 const lastDigit = toothNumber.charAt(1);
    //                 // let state = ["7", "8", "4", "1"].includes(lastDigit) ? ERUPTION_STATES.PENDING : ERUPTION_STATES.ERUPTED;
    //                 // let state = (['26', '16'].includes(toothNumber)) ? ERUPTION_STATES.ERUPTED : ERUPTION_STATES.PENDING;
    //                 let state = ERUPTION_STATES.PENDING;
    //                 //let state = ERUPTION_STATES.ERUPTED;
    //                 if (state == ERUPTION_STATES.PENDING) {
    //                     console.log('simulating non-erupted ' + toothNumber);
    //                 }
    //                 response.data.teeth[date][toothNumber].eruption = state;
    //             }
    //         });
    //     });
    //     return response;
    // }

    setLocale(locale) {
        this.locale = locale;
    }

    setSessionToken(token) {
        this.sessionToken = token;
    }

    clearSessionToken() {
        this.sessionToken = null;
    }

    wait(ms, promise) {
        return new Promise((resolve) => setTimeout(resolve.bind(null, promise), ms));
    }

    togglePatientModifiability(modifiable) {
        this.patientIsModifiable = modifiable;
    }

    ensurePatientModifiability(overridePatientModifiabilityRestrictions) {
        if (this.patientIsModifiable || overridePatientModifiabilityRestrictions) {
            return Promise.resolve();
        } else {
            // this is just a last-resort safeguard against accidentally patching the patient's teeth state
            // in case a historical state is currently loaded via rollback.

            // normally, all places in the application where write operations get initiated,
            // must locally ensureTeethStateModifiability() to prevent the update request
            // and display a user-friendly warning message before the code reaches here.

            // if code somehow reaches this point, then a simple rejection is returned which throws an error
            //throw new Error('Patient state is not modifiable');
            return Promise.reject(new Error('Patient state is not modifiable'));
        }
    }

    legacyRequest(params) {
        const contentType = (params.method == "patch") ? "application/json-patch+json" : 'application/json';

        if (!params.headers) {
            params.headers = {};
        }

        params.headers['Content-Type'] = contentType;
        params.headers['Request-Locale'] = this.locale;

        // uncomment this wrapper to simulate slow API responses
        // return this.wait(3000).then(() => {
        return this.legacyAxios.request(params);
        // });
    }

    coreApiRequest(params) {
        const contentType = (params.method == "patch") ? "application/json-patch+json" : 'application/json';

        if (!params.headers) {
            params.headers = {};
        }

        params.headers['Content-Type'] = contentType;
        params.headers['Request-Locale'] = this.locale;

        // uncomment this wrapper to simulate slow API responses
        // return this.wait(3000).then(() => {
        return this.coreAxios.request(params);
        // });
    }

    patientsApiRequest(params) {
        const contentType = 'application/vnd.api+json';

        if (!params.headers) {
            params.headers = {};
        }

        params.headers['Content-Type'] = contentType;
        params.headers['Request-Locale'] = this.locale;

        // uncomment this wrapper to simulate slow API responses
        // return this.wait(3000).then(() => {
        return this.patientsAxios.request(params);
        // });
    }

    coreSessionRequest(params) {
        if (!this.sessionToken) {
            throw "API session request called without a session token.";
        }

        if (!params.headers) {
            params.headers = {};
        }

        params.headers['Session-Token'] = this.sessionToken;

        return this.coreApiRequest(params);
    }

    patientsSessionRequest(params) {
        if (!this.sessionToken) {
            throw "API session request called without a session token.";
        }

        if (!params.headers) {
            params.headers = {};
        }

        params.headers['Session-Token'] = this.sessionToken;

        return this.patientsApiRequest(params);
    }

    legacySessionRequest(params) {
        if (!this.sessionToken) {
            throw "API session request called without a session token.";
        }

        if (!params.headers) {
            params.headers = {};
        }
        // automatically prepend current session path
        params.headers['Session-Token'] = this.sessionToken;
        return this.legacyRequest(params);
    }

    getSession(sessionToken) {
        const params = {
            method: 'get',
            url: 'sessions',
            headers: {
                'Session-Token': sessionToken
            }
        };
        return this.legacyRequest(params);
    }

    createSession(email, password, device_id) {
        var parameters = {email, password, device_id};
        return this.legacyRequest({method: 'post', url: 'sessions', data: parameters});
    }

    createSessionFromToken(login_token, device_id) {
        var parameters = {login_token, device_id};
        return this.legacyRequest({method: 'post', url: 'sessions/login_token', data: parameters});
    }

    deleteCurrentSession() {
        return this.legacySessionRequest({method: 'delete', url: 'sessions'});
    }

    getRegistrationTerms() {
        return this.coreApiRequest({method: 'get', url: 'terms'});
    }

    getOauthToken() {
        return this.coreApiRequest({method: 'get', url: 'oauth/token'});
    }

    createOauthAppleSignInSessionToken(code, state) {
        var parameters = {code, state};
        return this.coreApiRequest(
            {method: 'post', url: 'oauth/apple-sign-in', data: parameters, timeout: this.slowRequestTimeout});
    }

    getTerms() {
        return this.legacySessionRequest({url: 'terms'});
    }

    acceptTerms() {
        return this.legacySessionRequest({method: 'post', url: 'terms', data: {accepted: true}});
    }

    getPatientDataProcessingAgreement(patientId) {
        const url = 'patients/' + encodeURIComponent(patientId) + '/privacy';
        return this.legacySessionRequest({url});
    }

    acceptPatientDataProcessingAgreement(patientId) {
        const url = 'patients/' + encodeURIComponent(patientId) + '/privacy';
        return this.legacySessionRequest({method: 'post', url, data: {accepted: true}});
    }

    registration(params) {
        return this.coreApiRequest({method: 'post', url: 'profile', data: params, timeout: this.slowRequestTimeout});
    }

    subscriptionsRead() {
        return this.coreSessionRequest({method: 'get', url: 'billing/subscriptions', timeout: this.slowRequestTimeout});
    }

    subscriptionCreate(priceId) {
        return this.coreSessionRequest(
            {method: 'patch', url: 'billing/subscriptions/' + priceId, timeout: this.slowRequestTimeout});
    }

    subscriptionCancel(priceId) {
        return this.coreSessionRequest(
            {method: 'delete', url: 'billing/subscriptions/' + priceId, timeout: this.slowRequestTimeout});
    }

    getStarted(email) {
        return this.legacyRequest(
            {method: 'post', url: 'user/get_started', data: {email}, timeout: this.slowRequestTimeout});
    }

    sendPasswordResetEmail(email) {
        return this.coreApiRequest(
            {method: 'post', url: 'password-reset', data: {email}, timeout: this.slowRequestTimeout});
    }

    resetPassword(token, password, passwordConfirmation) {
        const data = {
            password: password,
            password_confirmation: passwordConfirmation
        };

        return this.coreApiRequest(
            {method: 'post', url: 'password-reset/' + token, data: data, timeout: this.slowRequestTimeout});
    }

    updateProfile(user) {
        const patchOperations = Object.keys(user).map((attribute) => {
            return {
                op: "replace",
                path: "/" + attribute,
                value: user[attribute]
            };
        });

        const url = 'current_user/settings';
        return this.legacySessionRequest({method: 'patch', url, data: patchOperations});
    }

    createPatient(patient) {
        return this.legacySessionRequest({method: 'post', url: 'patients', data: patient});
    }

    patchPatient(patientId, patchOperations, overridePatientModifiabilityRestrictions) {
        return this.ensurePatientModifiability(overridePatientModifiabilityRestrictions).then(() => {
            const url = 'patients/' + encodeURIComponent(patientId);
            return this.legacySessionRequest({method: 'patch', url, data: patchOperations});
        });
    }

    updatePatient(patient, patientId) {

        const patchOperations = Object.keys(patient).map((attribute) => {
            return {
                op: "replace",
                path: "/" + attribute,
                value: patient[attribute]
            };
        });
        return this.patchPatient(patientId, patchOperations, true);
    }

    deletePatient(patientId) {
        return this.legacySessionRequest({method: 'delete', url: 'patients/' + encodeURIComponent(patientId)});
    }

    getPatientList(queryString, offset, limit) {
        const params = {
            query_string: queryString,
            offset,
            limit
        };

        if (this.getPatientListRequest) {
            this.getPatientListRequest.cancel();
        }

        const cancellableRequest = axios.CancelToken.source();
        const cancelToken = cancellableRequest.token;
        this.getPatientListRequest = cancellableRequest;

        return this.legacySessionRequest({method: 'get', url: 'patient_list', params, cancelToken}).catch((error) => {
            if (axios.isCancel(error)) {
                error.requestCancelled = true;
            }
            throw error;
        });
    }

    getPatient(patientId) {
        const params = {"full_teeth_history": "1"};
        return this.legacySessionRequest({method: 'get', url: 'patients/' + encodeURIComponent(patientId), params});
    }

    updateMedicalIssues(patientId, medicalIssues) {
        const patchOperations = [{
            op: "replace",
            path: "/attribute_values/medical_issues",
            value: medicalIssues
        }];

        return this.patchPatient(patientId, patchOperations);
    }

    updateOralHealthIssues(patientId, oralHealthIssues) {
        const patchOperations = [{
            op: "replace",
            path: "/attribute_values/oral_health",
            value: oralHealthIssues
        }];

        return this.patchPatient(patientId, patchOperations);
    }

    updateTeethStates(patientId, teethStateValues) {
        let patchOperations = [];
        Object.keys(teethStateValues).forEach(toothNumber => {
            const stateValues = teethStateValues[toothNumber];
            Object.keys(stateValues).forEach(stateKey => {
                const stateValue = stateValues[stateKey];
                patchOperations.push({
                    op: "replace",
                    path: "/teeth/current_state/" + encodeURIComponent(toothNumber) + "/" + stateKey,
                    value: stateValue
                });
            });
        });
        return this.patchPatient(patientId, patchOperations);
    }

    resetToothToState(patientId, toothNumber, stateValues) {
        const patchOperations = [{
            op: "replace",
            path: "/teeth/current_state/" + encodeURIComponent(toothNumber),
            value: stateValues
        }];

        return this.patchPatient(patientId, patchOperations);
    }

    patientRequest(patientId, params) {
        // automatically prepend patient path
        params.url = 'patients/' + encodeURIComponent(patientId) + '/' + params.url;
        return this.patientsSessionRequest(params);
    }

    legacyPatientRequest(patientId, params) {
        // automatically prepend patient path
        params.url = 'patients/' + encodeURIComponent(patientId) + '/' + params.url;
        return this.legacySessionRequest(params);
    }

    createHistoryRecord(patientId, {history_record_id, content, source_tooth_number}) {
        return this.ensurePatientModifiability().then(() => {
            const url = 'history_records';
            const data = {history_record_id, content, source_tooth_number}; // ignore other keys
            return this.legacyPatientRequest(patientId, {method: 'post', url, data});
        });
    }

    deleteHistoryRecord(patientId, recordId) {
        return this.ensurePatientModifiability().then(() => {
            return this.legacyPatientRequest(patientId, {
                method: 'delete',
                url: 'history_records/' + encodeURIComponent(recordId)
            });
        });
    }

    createIssueRecord(patientId, {issue_record_id, type, status, content, source_tooth_number}) {
        return this.ensurePatientModifiability().then(() => {
            // type is 'urgent' or 'monitor'
            // status is 'active' or 'resolved'
            const url = 'issue_records';
            const data = {issue_record_id, type, status, content, source_tooth_number}; // ignore other keys
            return this.legacyPatientRequest(patientId, {method: 'post', url, data});
        });
    }

    resolveIssueRecord(patientId, recordId) {
        return this.ensurePatientModifiability().then(() => {
            const url = 'issue_records/' + encodeURIComponent(recordId);
            const patchOperations = [{
                op: "replace",
                path: "/status",
                value: ISSUE_STATUSES.RESOLVED
            }];
            return this.legacyPatientRequest(patientId, {method: 'patch', url, data: patchOperations});
        });
    }

    deleteIssueRecord(patientId, recordId) {
        return this.ensurePatientModifiability().then(() => {
            return this.legacyPatientRequest(patientId, {
                method: 'delete',
                url: 'issue_records/' + encodeURIComponent(recordId)
            });
        });
    }

    createBasicPeriodontalExaminationRecords(patientId, scores) {
        return this.ensurePatientModifiability().then(() => {
            const url = "operations";
            const data = scores.reduce((data, score) => {
                data["atomic:operations"].push({
                    "op": "add",
                    "data": {
                        "type": "basicPeriodontalExaminationRecord",
                        "attributes": score,
                    },
                    "relationships": {
                        "patient": {
                            "data": {
                                "type": "patient",
                                "id": patientId
                            }
                        }
                    }
                });
                return data;
            }, {"atomic:operations": []});
            return this.patientsSessionRequest({method: "post", url, data}).then((response) => {
                return response.data["atomic:results"];
            });
        });
    }

    createSoftTissueRecords(patientId, entries) {
        return this.ensurePatientModifiability().then(() => {
            const url = "operations";
            const data = entries.reduce((data, entry) => {
                data["atomic:operations"].push({
                    "op": "add",
                    "data": {
                        "type": "softTissueRecord",
                        "attributes": entry,
                    },
                    "relationships": {
                        "patient": {
                            "data": {
                                "type": "patient",
                                "id": patientId
                            }
                        }
                    }
                });
                return data;
            }, {"atomic:operations": []});
            return this.patientsSessionRequest({method: "post", url, data}).then((response) => {
                return response.data["atomic:results"];
            });
        });
    }

    createPrintJob(params) {
        return this.legacySessionRequest({method: 'post', url: 'reports', data: params});
    }

    getPrintJobStatus(id) {
        return this.legacySessionRequest({method: 'get', url: 'reports/' + encodeURIComponent(id) + '/status'});
    }
}

export default {
    install(Vue, {apiOrigin, store}) {
        // expose api client to components and store as a top-level member
        Vue.prototype.$api = new ApiClient(apiOrigin);
        store.$api = Vue.prototype.$api;

        // expose centralized error handler as a top-level store member for easier usage syntax
        store.apiErrorHandler = (error) => {
            return store.dispatch('api/handleError', error);
        };

        // expose error handler to components as well
        Vue.mixin(
            {
                methods: {
                    apiErrorHandler(error) {
                        return store.apiErrorHandler(error);
                    }
                }
            });
    }
};
